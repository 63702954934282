import config from "./config";

let analyticsInjected = false;

const cookiesAcceptedCookieName = "cookiesAccepted";

function getCookies(): Record<string, string> {
  return document.cookie.split("; ").reduce((acc, cookie) => {
    const [key, value] = cookie.split("=");
    return { ...acc, [key]: value };
  }, {});
}

export function hasAnalyticsCookieResponse(): boolean {
  return getCookies()[cookiesAcceptedCookieName] !== undefined;
}

export function rejectCookies() {
  document.cookie = "cookiesAccepted=false;path=/;samesite=strict";
}

export function acceptCookies() {
  document.cookie = "cookiesAccepted=true;path=/;samesite=strict";
  initAnalytics();
}

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer: any[];
  }
}

export function initAnalytics() {
  if (analyticsInjected) {
    return;
  }
  const cookies = getCookies();

  const cookiesAccepted = cookies[cookiesAcceptedCookieName] === "true";
  if (cookiesAccepted) {
    analyticsInjected = true;

    if (config.googleAnalyticsAppId === "") {
      console.warn("Google Analytics ID not set");
      return;
    }

    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://www.googletagmanager.com/gtag/js?id=" +
      config.googleAnalyticsAppId;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const gtag = (...args: any[]) => {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
    gtag("js", new Date());
    gtag("config", config.googleAnalyticsAppId);
  }
}
