import styled from "@emotion/styled";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRef, useState } from "react";

import Icon from "~/components/Icon";
import ThumbnailPlayView, {
  ThumbnailPlayViewRef,
} from "~/components/ThumbnailPlayView";
import appState from "~/library/appState";
import { dataUrlToFile } from "~/library/fileUtils";
import serverApi from "~/library/serverApi/serverApi";
import { serverApiUpdateThumbnail } from "~/library/serverApi/serverApiUpdateThumbnail";

import { exploreModal } from ".";

const LicenseGrantText = styled(Typography)({
  a: {
    color: "white",
  },
});

const Thumbnail = styled.div<{ url: string }>(({ url }) => ({
  position: "relative",
  width: "100%",
  height: "0",
  paddingBottom: "56%",
  backgroundSize: "cover",
  backgroundImage: `url('${url}')`,
  borderRadius: "4px",
}));

const ThumbnailControl = styled.div({
  position: "absolute",
  right: "10px",
  top: "10px",
});

const PublishExploreProjectModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [publishing, setPublishing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const project = appState.project!;

  const [name, setName] = useState(project.name);
  const [description, setDescription] = useState(project.description);
  const [editThumbnail, setEditThumbnail] = useState(!project.thumbnailId);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const thumbnailRef = useRef<ThumbnailPlayViewRef>();

  const publishExploreProject = () => {
    setError(null);

    if (publishing) {
      return;
    }

    if (!name || !description || !agreedToTerms) {
      setError("Please complete all fields and agree to the terms.");
      return;
    }

    setPublishing(true);

    // Flush the file changes so that the Explore version that is then requested to be published is up-to-date
    const flushedCallback = async () => {
      project.session?.v1.off.v1_events_filesFlushed(flushedCallback);

      if (editThumbnail) {
        try {
          if (!thumbnailRef.current) {
            console.error(
              "thumbnailRef.current was assumed to be set, but is missing",
            );
            return;
          }

          const thumbnail = thumbnailRef.current.generateProjectThumbnail();
          const file = await dataUrlToFile(thumbnail, "thumbnail.png");
          const thumbnailRes = await serverApiUpdateThumbnail({
            projectId: project.id,
            asset: file,
          });
          if (thumbnailRes.code !== 200) {
            setError(
              "There was a problem uploading the thumbnail. Please try again.",
            );
            setPublishing(false);
            return;
          }
        } catch (e) {
          setError(
            "There was a problem generating the thumbnail. Please try again.",
          );
          setPublishing(false);
          return;
        }
      } else {
        if (project.thumbnailId === undefined) {
          console.error("thumbnailId was assumed to be set, but is missing");
          setError("A project must have a thumbnail to be published.");
          return;
        }
      }

      const res = await serverApi.publishExploreProject({
        parameters: {
          projectId: project.id,
        },
        body: {
          name,
          description,
          tags: [],
        },
      });

      if (res.code !== 200) {
        setError(
          "There was a problem publishing the project. Please try again.",
        );
        setPublishing(false);
        return;
      }

      setPublishing(false);
      exploreModal({});
    };
    project.session?.v1.on.v1_events_filesFlushed(flushedCallback);
    project.session?.v1.v1_requests_flushFileChanges({});
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Explore</DialogTitle>

      <DialogContent>
        <Stack spacing={1} sx={{ mb: 3 }}>
          <Typography>Publish Explore Project</Typography>
          <DialogContentText fontSize={13}>
            Publish the current state of your project to the MML Explore page.
            This will create a publicly accessible version of your project in
            its current state, including source code, that will not change even
            if you make changes to the document.
          </DialogContentText>
        </Stack>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Stack spacing={3} sx={{ mb: 3 }}>
          <TextField
            color="secondary"
            id="name"
            label="Project name"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!name}
          />
          <TextField
            color="secondary"
            id="description"
            label="Project description"
            type="text"
            autoComplete="off"
            fullWidth
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={!description}
          />
          <Stack spacing={1}>
            <DialogContentText fontSize={13}>Thumbnail</DialogContentText>
            {editThumbnail ? (
              <ThumbnailPlayView ref={thumbnailRef} />
            ) : (
              <Thumbnail url={project.thumbnailUrl!}>
                <ThumbnailControl>
                  <IconButton
                    color="primary"
                    onClick={() => setEditThumbnail(true)}
                  >
                    <Icon color="primary" icon="edit" size="16px" />
                  </IconButton>
                </ThumbnailControl>
              </Thumbnail>
            )}
          </Stack>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
              />
            }
            label={
              <LicenseGrantText variant="body2">
                I confirm that the code and assets of this project are my own,
                and I grant permission to license and showcase them on the MML
                and MML Editor websites under the{" "}
                <a
                  href="https://creativecommons.org/publicdomain/zero/1.0/deed.en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC0 1.0 Universal (Public Domain Dedication) license
                </a>
                .
              </LicenseGrantText>
            }
          />
        </Stack>
        <Button
          variant="contained"
          disabled={publishing}
          onClick={() => {
            if (!publishing) {
              publishExploreProject();
            }
          }}
        >
          {publishing ? "Publishing..." : "Publish"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default observer(PublishExploreProjectModal);
